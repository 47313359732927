import { Component, OnInit} from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})



export class HomeComponent implements OnInit {
  public collectionData: any;
  public isLoading: boolean = false;
  public uniquelabel: any;
  public searchParams = {
    "archived": 1
  }
  public archivedData: any;
  returnUrl: string;



  constructor(public rest:RestService, private route: ActivatedRoute, private router : Router) { }
  title: string; 
  ngOnInit() {
    this.returnUrl = '/all-images'; 
    this.isLoading = true;
    this.getCollections();
    this.showArchived();
    this.showUniquelabel();
    
  }
  getCollections(){
    this.rest.getAllCollections().subscribe((result) => {
      if(result.status == "success"){
        this.collectionData = result;
        this.isLoading = false;
      } else{
      }
    }, (err) => {
      // this.message = err.error;
    });
  }
  // Detail view starts here
  showCollections(title){
  // Call detail API
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "title": title
    }
  }
  this.router.navigate(['/collection-detail/'], navigationExtras);
}

    //Get unique label
    showUniquelabel(){
      this.rest.getUniqueLabel().subscribe((result) => {
        if(result.status == "success"){  
          this.uniquelabel = result;
          console.log(this.uniquelabel, 'uniquelabel');
          this.isLoading = false;
        } else{
         //this.message = result.message;
        }
      }, (err) => {
        //this.message = err.error;
      });
    }

    // Show archived images here
    showArchived(){
      this.rest.search(this.searchParams).subscribe((result) => {
        if(result.status == "success"){  
          this.archivedData = result;
          this.isLoading = false;
        } else{
         //this.message = result.message;
        }
      }, (err) => {
        //this.message = err.error;
      });
    }
    detailView(id){
      // Call detail API
      this.rest.viewProduct(id).subscribe((result) => {
        if(result.status == "success"){  
        let navigationExtras: NavigationExtras = {
          queryParams: {
            "assetid": id,
            "file_title": result.data["0"].file_title,
            "file_org_url": result.data["0"].file_org_url,
            "file_type": result.data["0"].file_type,
            "file_size": result.data["0"].file_size,
            "file_width": result.data["0"].file_width,
            "file_height": result.data["0"].file_height,
            "file_description": result.data["0"].file_description,
            "google_url": result.data["0"].google_url,
            "archived": result.data["0"].archived
          }
        }
            this.router.navigate(['/view/'], navigationExtras);
          } else{
          //this.message = result.message;
          }
        }, (err) => {
          //this.message = err.error;
        });
      }
      tagClick(keywordName:any) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            "keywords": keywordName
        }
        }
        this.router.navigate([this.returnUrl], navigationExtras);
        } 
}
