import { Component, OnInit, Renderer2 } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ShortlistModalComponent } from '../shortlist-modal/shortlist-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';
import { NgxSliderModule, Options } from "@angular-slider/ngx-slider";

export type rangeslider = Options;

@Component({
  selector: 'app-all-videos',
  templateUrl: './all-videos.component.html',
  styleUrls: ['./all-videos.component.css']
})
export class AllVideosComponent implements OnInit {
  public isLoading: boolean = false;
  public arrayData: any = [];
  selectedProduct: string;
  productForm: FormGroup;
  sizeForm: FormGroup;
  products: any = [];
  public filterData = {
    "minwidth":"",
    "minheight":""
  }
  showMessage: string;
  totalItems: number;
  slideSensor: boolean = true;
  pdStatus: boolean = false;
  dStatus: boolean = false;
  aStatus: boolean = false;
  allStatus: boolean = false;
  portraitStatus: boolean = false;
  landscapeStatus: boolean = false;
  all_type_status: boolean = false;
  image_type_status: boolean = true;
  illus_type_status: boolean = false;
  currentPageNumber: number;
  sizeControl;
  selectedSize: string;

  nrSelect:string = "Select Product";
  nrSelect01:string = "Select Size";
  public searchParams = {
    "keywords": "",
    "orientation": "",
    "product": "",
    "category": "",
    "page": 1,
    "sortby": "publish_date",
    "orderby": "DESC",
    "type": "vid",
    "minwidth": "",
    "minheight": "",
    "is_illustrator":"0",
    "minduration": "",
    "maxduration": "",
    "archived": "0"
  }
  public modalData = {
    image: '',
    id: ''
  }
  detailUrl: string;
  constructor(public rest:RestService, private route: ActivatedRoute, private fb: FormBuilder, private router : Router, private modalService: NgbModal, private renderer: Renderer2) {
    fromEvent(window, 'popstate')
  .subscribe((e) => {
    // console.log(e, 'back button');
    this.isLoading = true;
    this.updateUrl();
    this.showAllVidoes();
  });
  }


  minimumWidth: number = 0;
  minimumHeight: number = 0;


  minLenth: number = 1;
  maxLenth: number = 2000;

 optionsminLent: Options = {
    floor: 1,
    step: 10,
    ceil: 200
  };
  optionsmaxLent: Options = {
    floor: 10,
    step: 10,
    ceil: 2000
  };

  optionsWidth: Options = {
    floor: 0,
    step: 10,
    ceil: 8000
  };
  optionsHeight: Options = {
    floor: 0,
    step: 10,
    ceil: 8000
  };

  ngOnInit() {
    this.isLoading = true;
    this.getProductList();
    this.productForm = this.fb.group({

      productControl: new FormControl('', Validators.compose([
      ]))
      // more form inputs
    })
    this.sizeForm = this.fb.group({
      sizeControl: new FormControl('', Validators.compose([
      ])),
      minimumWidth: new FormControl('', Validators.compose([
      ])),
        minLenth: new FormControl('', Validators.compose([
      ])),
        maxLenth: new FormControl('', Validators.compose([
      ])),
      minimumHeight: new FormControl('', Validators.compose([
      ]))
      // more form inputs
    })


    if(this.route.queryParams["value"].is_illustrator == 1){
      this.all_type_status = false;
      this.image_type_status = false;
      this.illus_type_status = true;
      this.searchParams.type = "all";
      this.searchParams.is_illustrator = "1";
    } else if(this.route.queryParams["value"].is_illustrator == 0 && this.route.queryParams["value"].type == "vid"){
      this.all_type_status = false;
      this.image_type_status = true;
      this.illus_type_status = false;
      this.searchParams.type = "vid";
      this.searchParams.is_illustrator = "0";
    } else if(this.route.queryParams["value"].is_illustrator == 0 && this.route.queryParams["value"].type == "all") {
      this.all_type_status = true;
      this.image_type_status = false;
      this.illus_type_status = false;
      this.searchParams.type = "all";
      this.searchParams.is_illustrator = "0";
    }

    // Sort by here
    if(this.searchParams.sortby == 'publish_date'){
      this.pdStatus = true;
      this.dStatus = false;
      this.aStatus = false;
      this.searchParams.orderby = 'DESC';
    }
    if(this.searchParams.sortby == 'DESC'){
      this.pdStatus = false;
      this.dStatus = true;
      this.aStatus = false;
      this.searchParams.sortby = 'asset_size';

    }
    if(this.searchParams.sortby == 'ASC'){
      this.pdStatus = false;
      this.dStatus = false;
      this.aStatus = true;
      this.searchParams.sortby = 'asset_size';

    }

    // Orientation here
    if(this.searchParams.orientation == ''){
      this.allStatus = true;
      this.portraitStatus = false;
      this.landscapeStatus = false;
    }
    if(this.searchParams.orientation == 'portrait'){
      this.allStatus = false;
      this.portraitStatus = true;
      this.landscapeStatus = false;
    }
    if(this.searchParams.orientation == 'landscape'){
      this.allStatus = false;
      this.portraitStatus = false;
      this.landscapeStatus = true;
    }

    // Product list here
    this.selectedProduct = this.searchParams.product;


    if(this.route.queryParams["value"] !== ''){
      this.route.queryParams.subscribe(params => {
        // this.assetid = params["assetid"];
        // this.detailResult = params["result"];
        this.searchParams.keywords = params.keywords;
        this.searchParams.orderby = params.orderby;
        this.searchParams.orientation = params.orientation;
        this.searchParams.sortby = params.sortby;
        this.searchParams.product = params.product;
        this.searchParams.minwidth = params.minwidth;
        this.searchParams.minheight = params.minheight;
        this.searchParams.page = params.page;
        //this.searchParams.type = params.type;
        this.searchParams.minduration = params.minduration;
        this.searchParams.maxduration = params.maxduration;


        if(this.searchParams.orientation == 'portrait')
        {
          this.allStatus = false;
          this.portraitStatus = true;
          this.landscapeStatus = false;
        }
        if(this.searchParams.orientation == 'all')
        {
          this.allStatus = true;
          this.portraitStatus = false;
          this.landscapeStatus = false;
        }
        if(this.searchParams.orientation == 'landscape')
        {
          this.allStatus = false;
          this.portraitStatus = false;
          this.landscapeStatus = true;
        }

        if(this.searchParams.orderby == 'publish_date'){
          this.pdStatus = true;
          this.dStatus = false;
          this.aStatus = false;
        }
        if(this.searchParams.orderby == 'DESC'){
          this.pdStatus = false;
          this.dStatus = true;
          this.aStatus = false;
        }
        if(this.searchParams.orderby == 'ASC'){
          this.pdStatus = false;
          this.dStatus = false;
          this.aStatus = true;
        }

        if(this.searchParams.product == undefined){
          this.nrSelect = 'Select Product';
        }
        else{
          this.nrSelect = this.searchParams.product;
        }

        this.pageChanged(this.searchParams.page);

        if(parseInt(this.searchParams.minduration) > 0){
          this.minLenth = parseInt(this.searchParams.minduration);
        }
        if(parseInt(this.searchParams.maxduration) > 0){
          this.maxLenth = parseInt(this.searchParams.maxduration);
        }

        if(parseInt(this.searchParams.minwidth) > 0){
          this.minimumWidth = parseInt(this.searchParams.minwidth);
        }
        if(parseInt(this.searchParams.minheight) > 0){
          this.minimumHeight = parseInt(this.searchParams.minheight);
        }

    });

    this.updateUrl();
    this.showAllVidoes();

    } else{
    }

  }

  get f() { return this.sizeForm; }

  // For pagination click
  pageChanged(event){
    this.isLoading = true;
    this.searchParams.page = event;
    this.currentPageNumber = event;
    this.updateUrl();
    this.showAllVidoes();
  }

  // get all product list
  getProductList(){
    this.rest.getProductList().subscribe((result) => {
      this.products = result;
    });
  }
  // Main firing API
  showAllVidoes(){
    // console.log("here we are checking request params for function video init", this.searchParams)
    this.rest.search(this.searchParams).subscribe((result) => {
      if(result.status == "success"){
        this.arrayData = result;
        //console.log(this.arrayData);

        this.totalItems = result.total_rows;
        if(this.totalItems == 0){
          this.showMessage = result.msg;
        }
        this.isLoading = false;
      } else{
      }
    }, (err) => {
    });
  }


  // Tag search
  tagClick(name){
    this.isLoading = true;
    this.searchParams.keywords = name;
    this.showAllVidoes();
  }

  // min Duration change
  minDurationChange(minduration){
    //this.filterData.minwidth = minimumWidth;
    this.searchParams.minduration = minduration;
    this.updateUrl();
    this.showAllVidoes();
  }
  // max Duration change
  maxDurationChange(maxduration){
    //this.filterData.minheight = minimumHeight;
    this.searchParams.maxduration = maxduration;
    this.updateUrl();
    this.showAllVidoes();
  }

  widthChange(minimumWidth){
    //this.filterData.minwidth = minimumWidth;
    this.searchParams.minwidth = minimumWidth;
    this.updateUrl();
    this.showAllVidoes();
  }
  // Height change
  heightChange(minimumHeight){
    //this.filterData.minheight = minimumHeight;
    this.searchParams.minheight = minimumHeight;
    this.updateUrl();
    this.showAllVidoes();
  }

  // Sort by search
  sortBy(event: Event): void {
    this.isLoading = true;
    let elementId: string = (event.target as Element).id;
    this.searchParams.sortby = elementId;
    this.showAllVidoes();
  }
  // Orientation search
  orientationSearch(event: Event): void {
    this.isLoading = true;
    let elementId: string = (event.target as Element).id;
      this.searchParams.orientation =elementId;
      this.showAllVidoes();
  }


  // Update parameters
  updateUrl(){
    const queryParams = this.searchParams;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
      //console.log(queryParams, 'back params');

  }


  // On change product
  changeProductList (event: any) {
    this.isLoading = true;
    //update the ui
    this.selectedProduct = event.target.value;
    this.searchParams.product = this.selectedProduct;
    this.showAllVidoes();
  }

  // Search by similar
  similarSearch(similarName){
    this.isLoading = true;
    this.searchParams.keywords = similarName;
    this.showAllVidoes();
  }
  // Detail view starts here
  detailView(id){
  // Call detail API
  // debugger;
  this.rest.viewProduct(id).subscribe((result) => {
    if(result.status == "success"){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "assetid": id,
        "file_title": result.data["0"].file_title,
        "file_org_url": result.data["0"].file_org_url,
        "file_type": result.data["0"].file_type,
        "file_size": result.data["0"].file_size,
        "file_width": result.data["0"].file_width,
        "file_height": result.data["0"].file_height,
        "file_description": result.data["0"].file_description,
        "google_url": result.data["0"].google_url,
        "archived": result.data["0"].archived
      }
    }
        this.router.navigate(['/view/'], navigationExtras);
      } else{
      //this.message = result.message;
      }
    }, (err) => {
      //this.message = err.error;
    });
  }
  openModal(img, id) {
    const modalRef = this.modalService.open(ShortlistModalComponent);
    modalRef.componentInstance.modalData = this.modalData;
    this.modalData.image = img;
    this.modalData.id = id;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.modalService.dismissAll();
      alert(receivedEntry);
      })
    }

    visibleIndex = -1;
    showSubItem(ind) {
      if (this.visibleIndex === ind) {
        this.visibleIndex = -1;
      } else {
        this.visibleIndex = ind;
      }
    }

    slideF(){
      // console.log("hi");
       this.slideSensor = !this.slideSensor;
     }

     slidebtn(){
        this.slideSensor = !this.slideSensor;
      }

}
