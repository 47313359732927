import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';

@Component({
  selector: 'app-asset-upload',
  templateUrl: './asset-upload.component.html',
  styleUrls: ['./asset-upload.component.css']
})
export class AssetUploadComponent implements OnInit {
  public isLoading: boolean = false;
  public showSpinner:boolean = false;
  radios = 'individual';
  products: any = [];
  nrSelect;
  multiNrSelect;
  individualForm: FormGroup;
  multipleForm: FormGroup;
  individualMsg: string;
  multipleMsg: string;
  fileValue: FileList;
  fileValue2: FileList;
  individualDisclaimer: string;
  collectionDisclaimer: string;
  individualSuccess: string;
  collectionSuccess: string;
  public percentage: number = 100;
  progress: number = 0;
  clickValue:any = 0;
  clickValueColl:any = 0;

  constructor(
    private fb : FormBuilder,
    public rest:RestService,
    public router: Router
    ) { }

  ngOnInit() {
    this.getProductList();
    this.individualForm = this.fb.group({
      title: new FormControl('', Validators.compose([
        Validators.required
      ])),
      productControl: new FormControl('', Validators.compose([
        Validators.required
      ])),
      tags: new FormControl('', Validators.compose([
        Validators.required
      ])),
      importFile: new FormControl('', Validators.required),
      isillu: new FormControl('', Validators.compose([
        Validators.required
      ])),
    })
    this.multipleForm = this.fb.group({
      multiTitle: new FormControl('', Validators.compose([
        Validators.required
      ])),
      multiProductControl: new FormControl('', Validators.compose([
        Validators.required
      ])),
      tags2: new FormControl('', Validators.compose([
        Validators.required
      ])),
      importFile2: new FormControl('', Validators.required),
      isilluColl: new FormControl('', Validators.compose([
        Validators.required
      ])),
    })


  }
  get f() { return this.individualForm; }
  get f2() { return this.multipleForm; }
  clickIllus(value){
    console.log(value, 'clickVal');
    this.clickValue = value;
  }
  clickIllusColl(value){
    console.log(value, 'clickVal');
    this.clickValueColl = value;
  }
  setRadio(e: string): void
  {
    this.radios = e;
    console.log('model', this.radios);
  }
  isSelected(name: string): boolean {
        if (!this.radios) { // if no radio button is selected, always return false so every nothing is shown
        return false;
  }
    return (this.radios === name); // if current radio button is selected, return true, else return false
  }
  // For submitting individual form
  submitIndividual(){
    // stop here if form is invalid
    if (this.individualForm.invalid) {
      return;
    } else {
      console.log(this.individualForm, 'here');
      this.showSpinner = true;
      console.log('val', this.individualForm);
      this.rest.uploadIndividual(this.individualForm.value, this.fileValue, this.clickValue).subscribe((event: HttpEvent<any>) => {
        console.log(this.individualForm.value, 'new value here');
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('User successfully created!', event.body);
            if(event.body.status == "success"){
              this.progress = 0;
              this.individualMsg = event.body.status;
              console.log(this.individualMsg);
              this.f.reset();
              document.querySelector('label#file_label_1').innerHTML = "<i class='fas fa-search'></i> Choose file";
              this.individualSuccess = "Your file has been uploaded successfully.";

              setTimeout(() => {
                //alert("Your file has been uploaded successfully.");
                // this.router.navigate(['/dashboard']);
              }, 2000);  //2s
            } else {
              this.individualMsg = event.body.status;
            }



        }
            // this.rest.uploadIndividual(this.individualForm.value, this.fileValue).subscribe((result) => {
            // if(result.status == "success"){
            //   this.percentage = 100;
            //   this.showSpinner = false;
            //   this.f.reset();
            //   document.querySelector('label#file_label_1').innerHTML = "<i class='fas fa-search'></i> Choose file";
            //   this.individualSuccess = "Your file has been uploaded successfully.";

            //   setTimeout(() => {
            //     //alert("Your file has been uploaded successfully.");
            //     // this.router.navigate(['/dashboard']);
            //   }, 2000);  //2s
            // } else{
            //   this.individualMsg = result.message;
            // }
          }, (err) => {
            this.individualForm.reset();
            this.individualMsg = err.error;
          });
    }
  }

  // For submitting multiple form
  submitMultiple(){
    // stop here if form is invalid
    if (this.multipleForm.invalid) {
      return;
    } else {
      this.showSpinner = true;
      console.log('val', this.multipleForm);
        this.rest.submitMultiple(this.multipleForm.value, this.fileValue2, this.clickValueColl).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${this.progress}%`);
              break;
            case HttpEventType.Response:
              console.log('User successfully created!', event.body);
              if(event.body.status == "success"){
                console.log(event.body, 'body');
                this.progress = 0;
                this.percentage = 100;
                  this.showSpinner = false;
                  this.f2.reset();
                  document.querySelector('label#file_label_2').innerHTML = "<i class='fas fa-search'></i> Choose file";
                  this.collectionSuccess = "Your file has been uploaded successfully.";

                  this.multipleMsg = event.body.status;
                  this.collectionSuccess = "Your files have been uploaded successfully.";
                  // setTimeout(() => {
                  //   this.router.navigate(['/dashboard']);
                  // }, 2000);  //2s
                } else{
                  this.multipleMsg = event.body.message;
                }

          }
          }, (err) => {
            this.multipleForm.reset();
            this.multipleMsg = err.error;
          });
    }
  }

  onFileChange(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      console.log(reader, 'reader');
      reader.onload = () => {
        this.individualForm.get('importFile').setValue({
          filename: file.name,
          filetype: file.type
          //value: reader.__zone_symbol__originalInstance.result.split(',')[1]
        })
      };
      this.fileValue = file;
      // console.log(reader.__zone_symbol__originalInstance, 'reader');
      console.log(this.fileValue, 'file');
      if(this.fileValue["size"] > 100000000){
        console.log('big');
        this.individualDisclaimer = "Upload may take a while for files more than 100mb";
      } else {
        console.log('small');
        this.individualDisclaimer = "";
      }
      if(this.fileValue["type"] == "application/x-zip-compressed"){
        this.individualDisclaimer = "Invalid file type";
      } else {
        this.individualDisclaimer = "";
      }
      var label1 = document.getElementById('file_label_1');
      label1.innerHTML = this.fileValue['name'];
    }
  }

  onFileChange2(event) {
    let reader2 = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file2 = event.target.files[0];
      reader2.readAsDataURL(file2);
      console.log(reader2, 'reader');
      reader2.onload = () => {
        this.multipleForm.get('importFile2').setValue({
          filename: file2.name,
          filetype: file2.type
          //value: reader.__zone_symbol__originalInstance.result.split(',')[1]
        })
      };
      this.fileValue2 = file2;
      // console.log(reader.__zone_symbol__originalInstance, 'reader');
      console.log(this.fileValue2, 'file');
      if(this.fileValue2["size"] > 100000000){
        console.log('big');
        this.collectionDisclaimer = "Upload may take a while for files more than 100mb";
      } else {
        console.log('small');
        this.collectionDisclaimer ="";
      }
      var label2 = document.getElementById('file_label_2');
      label2.innerHTML = this.fileValue2['name'];
    }
  }

  // get all product list
  getProductList(){
    this.rest.getProductList().subscribe((result) => {
      this.products = result;
    });
  }

  // changeProductList (event: any) {
  //   //update the ui
  //   this.selectedProduct = event.target.value;
  // }
}
