import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../guards/auth.guard';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';
import { filter } from 'rxjs/operators';
import { Router, NavigationExtras, NavigationEnd, ActivatedRoute, UrlSegment  } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
// import { from } from 'rxjs';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // message: string;
  // resetMsg: string;
  id: string;
  placeId: string;
  active: boolean;
  authorised: boolean = false;
  showNavigation: boolean = false;
  showmobileNav: boolean = false;
  public data: any;
  //showmobileNavdrop: boolean = false;
  userData: any;

  // Declare API url
 editUrl = environment.editorUrl

//search keywords
searchKeywords:string="";

  constructor(
    private authGuard : AuthGuard,
    public rest:RestService,
    private fb : FormBuilder,
    private router : Router,
    private authService: AuthService,
    private route:ActivatedRoute
    ) {
      if(this.authGuard.isLoggedIn() == true){
        this.authorised = true;
      } else{
        this.authorised = false;
      }
   }

   searchForm: FormGroup;
   returnUrl: string;
  ngOnInit() {
    this.id = sessionStorage.getItem('token');
    this.returnUrl = '/all-images';
    if(this.authGuard.isLoggedIn() == true){
      this.authorised = true;
    } else{
      this.authorised = false;
    }

  // For login form
  this.searchForm = this.fb.group({
    keywords: new FormControl('', Validators.compose([
      //Validators.required,
    ]))
    // more form inputs
  })

  this.getUserDetails();
  }

  get f() { return this.searchForm; }
  search(keywordName:any) {
   
   let url= this.router.url.split('?');
   if (url[0]=="/all-videos") {
     this.returnUrl="/all-videos"
   }
   this.searchKeywords = keywordName;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "keywords": keywordName
      }
    }
    //this.router.navigate([this.returnUrl], navigationExtras);

     /* this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }*/
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.returnUrl], navigationExtras);
    }
   searchKeywordExist(url){
     console.log(this.searchKeywords);
     let navigationExtras: NavigationExtras = {
      queryParams: {
        "keywords": this.searchKeywords
      }
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([url], navigationExtras);
   }

    showNav(){
      this.showNavigation = true;
    }
    hideNav(){
      this.showNavigation = false;
    }

    // Logout function
    logout(){
      this.rest.logout().subscribe((result) => {
        if(result.status == "success"){
          this.authService.logout();
          this.authorised = false;
          this.router.navigate(['']);
        } else{
        }
      }, (err) => {
      });
    }

    // Get user details
    getUserDetails(){
      this.rest.userDetails().subscribe((result) => {
        if(result.status == "success"){
          //console.log(result, 'new result');
          this.userData = result.data;
        } else{
        }
      }, (err) => {
      });
    }

    navshow(){
      this.showmobileNav = !this.showmobileNav;
    }

   /*  navshowdrop(){
      this.showmobileNavdrop = !this.showmobileNavdrop;
    }
 */
    showmobileNavdrop = false;
    navshowdrop(ind) {
      if (this.showmobileNavdrop === ind) {
        this.showmobileNavdrop = false;
      } else {
        this.showmobileNavdrop = ind;
      }
    }

}
