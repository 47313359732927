import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import * as google_api_keys from "../../assets/json/google_keys.json"; 
declare var gapi:any;
declare var google:any;
let googleasset = [];
let textlabel = [];
let googleaccesstoken;
let project_id = google_api_keys.web.project_id;
let developerKey = google_api_keys.web.api_key;


@Component({
  selector: 'app-google-asset-upload',
  templateUrl: './google-asset-upload.component.html',
  styleUrls: ['./google-asset-upload.component.css']
})
export class GoogleAssetUploadComponent implements OnInit {

  public isLoading: boolean = false;
  public showSpinner:boolean = false;
  radios = '0';
  individualForm: FormGroup;
  individualMsg: string;
  individualDisclaimer: string;
  individualSuccess: string;
  public percentage: number = 100;
  progress: number = 0;
  clickValue:any = 0;
  clickValueColl:any = 0;
  pickerApiLoaded: boolean = false;
  oauthToken;



 /* google picker */
 clientId = google_api_keys.web.client_id;
 scope = ['https://www.googleapis.com/auth/drive.file',
 'https://www.googleapis.com/auth/drive',
 'https://www.googleapis.com/auth/drive.file',
 'https://www.googleapis.com/auth/drive.metadata'
];


  constructor(
    private fb : FormBuilder,
    public rest:RestService,
    public router: Router,
    private el: ElementRef
  ) { }


  onApiLoad() {

    gapi.load('auth', {'callback': this.onAuthApiLoad.bind(this)});
    gapi.load('picker', {'callback': this.onPickerApiLoad.bind(this)});

  }


  onAuthApiLoad() {
    gapi.auth.authorize(
        {
          'client_id': this.clientId,
          'scope': this.scope,
          'immediate': false
        },
        this.handleAuthResult);
        console.log(this.handleAuthResult)

  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
    //this.createPicker();

    this.individualForm.get('googleimportfile01').setValue({
      gaseet: googleasset,
      gatoken: googleaccesstoken
    })

  }


  handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
       if (authResult.access_token) {
        var view = new google.picker.DocsView();
        view.setMimeTypes('image/png,image/jpg,image/jpeg')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true);

        var picker = new google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .setAppId(project_id)
            .setOAuthToken(authResult.access_token)
            .addView(view)
            .setDeveloperKey(developerKey)
            .addView(new google.picker.DocsUploadView())
            .setCallback(function (data){
              if(data.action == google.picker.Action.PICKED)
            {

                 for(var i=0; i<data.docs.length; i++)
                {
                  googleasset.push({"fileId": data.docs[i].id, "oAuthToken":authResult.access_token, "name":data.docs[i].name});
                  let labeloverlap = [];
                  this.labeloverlap = document.getElementById('labeloverlap');
                  textlabel.push(data.docs[i].name);
                  //console.log(textlabel);
                  this.labeloverlap.innerHTML = textlabel.join(", ");
                  //console.log(googleasset[i].fileid);
                }
              }
            })
            .build();
        picker.setVisible(true);

      }
    }
  }




  ngOnInit() {
    this.individualForm = this.fb.group({
      title: new FormControl('', Validators.compose([
        Validators.required
      ])),
      tags: new FormControl('', Validators.compose([
        Validators.required
      ])),
      googleimportfile01: new FormControl('', Validators.compose([
        Validators.required
      ])),
      isillu: new FormControl('', Validators.compose([
        Validators.required
      ])),
    })

  }

  get f() { return this.individualForm; }

  clickIllus(value){
    //console.log(value, 'clickVal');
    this.clickValue = value;
  }
  clickIllusColl(value){
    //console.log(value, 'clickVal');
    this.clickValueColl = value;
  }

  setRadio(value)
  {
    this.radios = value;
    //console.log('model', this.radios);
  }

  focusFunction(){
    this.individualSuccess = "";
  }

  // For submitting individual form
  submitIndividual(){


    for(var i=0; i < googleasset.length; i++)
    {
      this.individualForm.get('googleimportfile01').setValue({
        fileId: googleasset[i].fileId,
        oAuthToken: googleasset[i].oAuthToken,
        name: googleasset[i].name,
      })

    // stop here if form is invalid
    if (this.individualForm.invalid) {
      return;

    } else {
      //console.log(this.individualForm, 'here');
      this.showSpinner = true;
         //console.log(this.individualForm.value);
          this.rest.googleuploadIndividual(this.individualForm.value, this.clickValue, this.radios).subscribe((result) => {
            var responsedata = result.slice(-7);
            //console.log(responsedata);
            if(responsedata == "success"){
              console.log(responsedata, 'result');
              this.isLoading = false;
              this.individualSuccess = "Your file has been uploaded successfully.";
              this.individualForm.reset();
              document.getElementById('labeloverlap').innerHTML = "";
              // Success
            } else {
              // Unsuccesful
            }

          }, (error) => {
            // Error
            this.individualForm.reset();
            this.individualSuccess = error.error;
          });
    }
  }
  }

}
