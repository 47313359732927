import { Component, OnInit, Renderer2 } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute, NavigationStart } from '@angular/router';
import { ShortlistModalComponent } from '../shortlist-modal/shortlist-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';
import { NgxSliderModule, Options } from "@angular-slider/ngx-slider";
import { environment } from 'src/environments/environment';

export type rangeslider = Options;


@Component({
  selector: 'app-all-images',
  templateUrl: './all-images.component.html',
  styleUrls: ['./all-images.component.css']
})
export class AllImagesComponent implements OnInit  {
  public isLoading: boolean = false;
  public arrayData: any = [];
  public x;
  /* minimumWidth: number;
  minimumHeight: number; */
  selectedProduct: string;
  productForm: FormGroup;
  sizeForm: FormGroup;
  products: any = [];
  public filterData = {
    "minwidth":"",
    "minheight":""
  }
  showMessage: string;
  totalItems: number;
  slideSensor: boolean = true;
  pdStatus: boolean = false;
  dStatus: boolean = false;
  aStatus: boolean = false;
  allStatus: boolean = false;
  portraitStatus: boolean = false;
  landscapeStatus: boolean = false;
  all_type_status: boolean = false;
  image_type_status: boolean = false;
  illus_type_status: boolean = false;
  currentPageNumber: number;
  sizeControl;
  selectedSize: string;

  nrSelect:string = "Select Product";
  nrSelect01:string = "Select Size";
  public searchParams = {
    "keywords": "",
    "orientation": "",
    "product": "",
    "category": "",
    "page": 1,
    "sortby": "publish_date",
    "orderby": "DESC",
    "type":"img",
    "minwidth": "",
    "minheight": "",
    "is_illustrator":"0",
    "dimension":""
  }
  public modalData = {
    image: '',
    id: ''
  }
  // dimensions=[
  //   {
  //     key:"Large",
  //     value:{
  //       height:"4000",
  //       width:"7000"
  //     }
  //   },
  //   {
  //     key:"Medium",
  //     value:{
  //       height:"500",
  //       width:"1000"
  //     }
  //   },
  //   {
  //     key:"Small",
  //     value:{
  //       height:"280",
  //       width:"500"
  //     }
  //   },
  //   {
  //     key:"Very small",
  //     value:{
  //       height:"320",
  //       width:"480"
  //     }
  //   },
  // ]
  dimensions=[
    {
      key:"Very Large (above 10000px)",
      value:"veryLarge"
    },
    {
      key:"Large (7000px - 10000px)",
      value:"large"
    },
    {
      key:"Medium (1000px - 6999px)",
      value:"medium"
    },
    {
      key:"Small (500px - 999px)",
      value:"small"
    },
    {
      key:"Very small (below 500px)",
      value:"verySmall"
    },
  ]
  dimension:string="";
  detailUrl: string;
  constructor(public rest:RestService, private route: ActivatedRoute, private fb: FormBuilder, private router : Router, private modalService: NgbModal, private renderer: Renderer2) {
    fromEvent(window, 'popstate')
  .subscribe((e) => {
    this.isLoading = true;
    this.updateUrl();
    this.showAllImages();
  });
  }


  minimumWidth: number = 10000;
  minimumHeight: number = 10000;


  optionsWidth: Options = {
    floor: 1000,
    step: 1000,
    ceil: 20000
  };
  optionsHeight: Options = {
    floor: 1000,
    step: 1000,
    ceil: 20000
  };


  ngOnInit() {
    this.isLoading = true;
    this.getProductList();
    this.productForm = this.fb.group({

      productControl: new FormControl('', Validators.compose([
      ]))
      // more form inputs
    })
    this.sizeForm = this.fb.group({
      sizeControl: new FormControl('', Validators.compose([
      ])),
      minimumWidth: new FormControl('', Validators.compose([
      ])),
      minimumHeight: new FormControl('', Validators.compose([
      ]))
      // more form inputs
    })



   // console.log(this.route.queryParams["value"], 'updated here');
    if(this.route.queryParams["value"].is_illustrator == 1){
      this.all_type_status = false;
      this.image_type_status = false;
      this.illus_type_status = true;
      this.searchParams.type = "all";
      this.searchParams.is_illustrator = "1";
    } else if(this.route.queryParams["value"].is_illustrator == 0 && this.route.queryParams["value"].type == "img"){
      this.all_type_status = false;
      this.image_type_status = true;
      this.illus_type_status = false;
      this.searchParams.type = "img";
      this.searchParams.is_illustrator = "0";
    } else if(this.route.queryParams["value"].is_illustrator == 0 && this.route.queryParams["value"].type == "all") {
      this.all_type_status = true;
      this.image_type_status = false;
      this.illus_type_status = false;
      this.searchParams.type = "all";
      this.searchParams.is_illustrator = "0";
    }

    // Sort by here
    if(this.searchParams.sortby == 'publish_date'){
      this.pdStatus = true;
      this.dStatus = false;
      this.aStatus = false;
      this.searchParams.orderby = 'DESC';
    }
    if(this.searchParams.sortby == 'DESC'){
      this.pdStatus = false;
      this.dStatus = true;
      this.aStatus = false;
      this.searchParams.sortby = 'asset_size';

    }
    if(this.searchParams.sortby == 'ASC'){
      this.pdStatus = false;
      this.dStatus = false;
      this.aStatus = true;
      this.searchParams.sortby = 'asset_size';

    }

    // Orientation here
    if(this.searchParams.orientation == ''){
      this.allStatus = true;
      this.portraitStatus = false;
      this.landscapeStatus = false;
    }
    if(this.searchParams.orientation == 'portrait'){
      this.allStatus = false;
      this.portraitStatus = true;
      this.landscapeStatus = false;
    }
    if(this.searchParams.orientation == 'landscape'){
      this.allStatus = false;
      this.portraitStatus = false;
      this.landscapeStatus = true;
    }

    // Product list here
    this.selectedProduct = this.searchParams.product;


    if(this.route.queryParams["value"] !== ''){
      this.route.queryParams.subscribe(params => {
        // this.assetid = params["assetid"];
        // this.detailResult = params["result"];
        this.searchParams.keywords = params.keywords;
        this.searchParams.orderby = params.orderby;
        this.searchParams.orientation = params.orientation;
        this.searchParams.sortby = params.sortby;
        this.searchParams.product = params.product;
        this.searchParams.minwidth = params.minwidth;
        this.searchParams.minheight = params.minheight;
        this.searchParams.page = params.page;

        if(this.searchParams.orientation == 'portrait')
        {
          this.allStatus = false;
          this.portraitStatus = true;
          this.landscapeStatus = false;
        }
        if(this.searchParams.orientation == 'all')
        {
          this.allStatus = true;
          this.portraitStatus = false;
          this.landscapeStatus = false;
        }
        if(this.searchParams.orientation == 'landscape')
        {
          this.allStatus = false;
          this.portraitStatus = false;
          this.landscapeStatus = true;
        }

        if(this.searchParams.orderby == 'publish_date'){
          this.pdStatus = true;
          this.dStatus = false;
          this.aStatus = false;
        }
        if(this.searchParams.orderby == 'DESC'){
          this.pdStatus = false;
          this.dStatus = true;
          this.aStatus = false;
        }
        if(this.searchParams.orderby == 'ASC'){
          this.pdStatus = false;
          this.dStatus = false;
          this.aStatus = true;
        }

        if(this.searchParams.product == undefined){
          this.nrSelect = 'Select Product';
        }
        else{
          this.nrSelect = this.searchParams.product;
        }

        this.pageChanged(this.searchParams.page);

        /* if(parseInt(this.searchParams.minwidth) > 0){
          this.minimumWidth = parseInt(this.searchParams.minwidth);
        }
        if(parseInt(this.searchParams.minheight) > 0){
          this.minimumHeight = parseInt(this.searchParams.minheight);
        } */

    });

    this.updateUrl();
    this.showAllImages();

    } else{
    }

  }


  /* ngDoCheck(){
    this.updateUrl();
    this.showAllImages();
  } */

  get f() { return this.sizeForm; }
  // For pagination click
  pageChanged(event){
    this.isLoading = true;
    this.searchParams.page = event;
    this.currentPageNumber = event;
    this.updateUrl();
    this.showAllImages();
  }
  // prevClick(){
  //   for (let i = 1; i < this.totalItems/20; i++) {
  //     this.searchParams.page = i;
  //     this.showAllImages();
  //   }
  // }

  // nextClick(){
  //   for (let i = 1; i < this.totalItems/20; i--) {
  //     this.searchParams.page = i;
  //     this.showAllImages();
  //   }
  // }

  // get all product list
  getProductList(){
    this.rest.getProductList().subscribe((result) => {
      this.products = result;
    });
  }
  // Main firing API
  showAllImages(){
    this.rest.search(this.searchParams).subscribe((result) => {
      if(result.status == "success"){
        this.arrayData = result;
        //console.log(this.arrayData);

        this.totalItems = result.total_rows;
        if(this.totalItems == 0){
          this.showMessage = result.msg;
        }
        this.isLoading = false;
      } else{
      }
    }, (err) => {
    });
  }
  // Tag search
  tagClick(name){
    this.isLoading = true;
    this.searchParams.keywords = name;
    this.updateUrl();
    this.showAllImages();
    //this.pageChanged(1);
  }
  // Width change
   widthChange(minimumWidth){
    //this.filterData.minwidth = minimumWidth;
    this.searchParams.minwidth = minimumWidth;
    this.updateUrl();
    this.showAllImages();
    //this.pageChanged(1);
  }
  // Height change
  heightChange(minimumHeight){
    //this.filterData.minheight = minimumHeight;
    this.searchParams.minheight = minimumHeight;
    this.updateUrl();
    this.showAllImages();
    //this.pageChanged(1);
  }
  changeDimensionList(e){
    //this.filterData.minheight = minimumHeight;
    this.searchParams.dimension =this.dimension;
    this.updateUrl();
    this.showAllImages();
    //this.pageChanged(1);
  }

  callSearch(){
    this.showAllImages();
    //this.pageChanged(1);
  }
  // Sort by search
  sortBy(event: Event): void {
    this.isLoading = true;
    let elementId: string = (event.target as Element).id;
    // console.log(elementId, 'elementid');
    //this.searchParams.orderBy = elementId;
    if(elementId == 'publish_date'){
      this.pdStatus = true;
      this.dStatus = false;
      this.aStatus = false;

      this.searchParams.sortby = elementId;
      this.searchParams.orderby = 'DESC';
    }
    if(elementId == 'DESC'){
      this.pdStatus = false;
      this.dStatus = true;
      this.aStatus = false;

      this.searchParams.sortby = 'asset_size';
      this.searchParams.orderby = elementId;

    }
    if(elementId == 'ASC'){
      this.pdStatus = false;
      this.dStatus = false;
      this.aStatus = true;

      this.searchParams.sortby = 'asset_size';
      this.searchParams.orderby = elementId;

    }
    this.updateUrl();
    this.showAllImages();
    this.pageChanged(1);
  }
  // Orientation search
  orientationSearch(event: Event): void {
    this.isLoading = true;
    let elementId: string = (event.target as Element).id;
    if(elementId == 'all'){
      this.allStatus = true;
      this.portraitStatus = false;
      this.landscapeStatus = false;
      this.searchParams.orientation = "";
    }
    if(elementId == 'portrait'){
      this.allStatus = false;
      this.portraitStatus = true;
      this.landscapeStatus = false;

      this.searchParams.orientation = elementId;
    }
    if(elementId == 'landscape'){
      this.allStatus = false;
      this.portraitStatus = false;
      this.landscapeStatus = true;
      this.searchParams.orientation = elementId;
    }
    this.updateUrl();
    this.showAllImages();
    this.pageChanged(1);
  }
  // Orientation search
  typeOf(event: Event): void {
    this.isLoading = true;
    let elementId: string = (event.target as Element).id;
    if(elementId == 'all_types'){
      this.all_type_status = true;
      this.image_type_status = false;
      this.illus_type_status = false;
      this.searchParams.type = "all";
      this.searchParams.is_illustrator = "0";
    }
    if(elementId == 'all_images'){
      this.all_type_status = false;
      this.image_type_status = true;
      this.illus_type_status = false;
      this.searchParams.type = "img";
      this.searchParams.is_illustrator = "0";
    }
    if(elementId == 'all_illus'){
      this.all_type_status = false;
      this.image_type_status = false;
      this.illus_type_status = true;
      this.searchParams.type = "all";
      this.searchParams.is_illustrator = "1";
    }
    this.updateUrl();
    this.showAllImages();
    this.pageChanged(1);
  }

  // Update parameters
  updateUrl(){
    const queryParams = this.searchParams;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
      //console.log(queryParams, 'back params');

  }

  // On change product
  changeProductList (event: any) {
    // console.log(event, 'event');
    this.isLoading = true;
    //update the ui
    this.selectedProduct = event.target.value;
    this.searchParams.product = this.selectedProduct;
    this.updateUrl();
    this.showAllImages();
    this.pageChanged(1);
  }


  // On change size
  changeSize (event: any) {

    this.isLoading = true;
    //update the ui
    this.selectedSize = event.target.value;
    //console.log(this.selectedSize, 'event');
    if(this.selectedSize == 'vsmall'){
      let num: number = 100;
      this.searchParams.minwidth = num.toString();
      this.searchParams.minheight = num.toString();
    }
    if(this.selectedSize == 'small'){
      let num: number = 500;
      this.searchParams.minwidth = num.toString();
      this.searchParams.minheight = num.toString();
    }
    if(this.selectedSize == 'medium'){
      let num: number = 1000;
      this.searchParams.minwidth = num.toString();
      this.searchParams.minheight = num.toString();
    }
    if(this.selectedSize == 'large'){
      let num: number = 5000;
      this.searchParams.minwidth = num.toString();
      this.searchParams.minheight = num.toString();
    }
    if(this.selectedSize == 'vlarge'){
      let num: number = 10000;
      this.searchParams.minwidth = num.toString();
      this.searchParams.minheight = num.toString();
    }
    this.updateUrl();
    this.showAllImages();
    this.pageChanged(1);

  }

  // Detail view starts here
  detailView(id){
  // Call detail API
  this.rest.viewProduct(id).subscribe((result) => {
    if(result.status == "success"){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "assetid": id,
        "file_title": result.data["0"].file_title,
        "file_org_url": result.data["0"].file_org_url,
        "file_type": result.data["0"].file_type,
        "file_size": result.data["0"].file_size,
        "file_width": result.data["0"].file_width,
        "file_height": result.data["0"].file_height,
        "file_description": result.data["0"].file_description,
        "google_url": result.data["0"].google_url,
        "archived": result.data["0"].archived,
        "page": this.searchParams.page
      }
    }
        this.router.navigate(['/view/'], navigationExtras);
      } else{
      //this.message = result.message;
      }
    }, (err) => {
      //this.message = err.error;
    });
  }
  openModal(img, id) {
    const modalRef = this.modalService.open(ShortlistModalComponent);
    modalRef.componentInstance.modalData = this.modalData;
    this.modalData.image = img;
    this.modalData.id = id;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.modalService.dismissAll();
      alert(receivedEntry);
      })
    }

    visibleIndex = -1;
    showSubItem(ind) {
      if (this.visibleIndex === ind) {
        this.visibleIndex = -1;
      } else {
        this.visibleIndex = ind;
      }
    }

    slideF(){
      // console.log("hi");
       this.slideSensor = !this.slideSensor;
       this.renderer.removeClass(document.body, 'overflowhide');
     }

    slidebtn(){
       this.slideSensor = !this.slideSensor;
       this.renderer.addClass(document.body, 'overflowhide');
     }

     downloadApi(assetid,google_url) {
     window.location.href=environment.siteUrl+'solr/getfile?path=' +google_url+ '&download=1';

     this.rest.downloadAsset(assetid).subscribe((result) => {
         if(result.status == "success"){
           //this.detailData = result;
           //this.downloadFile('filename', 'text');
         } else{
          //this.message = result.message;
         }
       }, (err) => {
         //this.message = err.error;
       });
     }

}
