import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

// Declare API url
const viewendpoint = environment.siteUrl;

@Component({
  selector: 'app-view-request',
  templateUrl: './view-request.component.html',
  styleUrls: ['./view-request.component.css']
})



export class ViewRequestComponent implements OnInit {
  public isLoading: boolean = false;
  public viewData: any = [];
  public assetpath :string;
  public vassetid :string;
  public message :string;
  public viewData01: any = [];
  public viewUrl: string;
  //public message: string;

  constructor(private router : Router, private route: ActivatedRoute, public rest:RestService) 
  { }

  ngOnInit() {
    this.isLoading = true;
    this.showProduct();
  }

  showProduct(){
   
    this.rest.showProduct().subscribe((result) => {
      if(result.status == "success"){  
        this.viewData = result;
        //console.log(this.viewData);
        this.isLoading = false;
      } else{
       //this.message = result.message;
      }
    }, (err) => {
      //this.message = err.error;
    });
  }


  


  /* downloadApi(assetpath) {
    console.log('assetpath', assetpath);
    this.rest.viewdownloadAsset(assetpath).subscribe((result) => {
      if(result.status == "success"){  
        this.viewData01 = result;
        console.log(this.viewData01);
        //this.downloadFile('filename', 'text');
        //this.router.navigate(this.viewData01); 
        
      } else{
       // this.router.navigate(this.viewData01); 
      }
    }, (err) => {
      //this.message = err.error;
    });
  } */

  downloadApi(assetpath, vassetid) {
    this.rest.downloadAsset(vassetid).subscribe((result) => {
      if(result.status == "success"){  
       //console.log(result);
        
      } else{
       //this.message = result.message;
       //console.log(this.message);
      }
    }, (err) => {
      //this.message = err.error;
    });

    this.viewUrl = viewendpoint + 'solr/getfile?path='+assetpath+"&download=1";
    //console.log(this.viewUrl);
    window.open(this.viewUrl);
    //this.router.navigate([this.viewUrl]); 
  }

}
