import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../guards/auth.guard';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  authorised: boolean = false;
  constructor(private authGuard : AuthGuard) {
    if(this.authGuard.isLoggedIn() == true){
      this.authorised = true;
    } else{
      this.authorised = false;
    }
  }

  ngOnInit() {
    if(this.authGuard.isLoggedIn() == true){
      this.authorised = true;
    } else{
      this.authorised = false;
    }
  }

}
