import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  message: string;
  errmessage;
  successmessage;

  constructor(
    private fb : FormBuilder,
    public rest:RestService
  
  ) { }

  forgotForm: FormGroup;  
  ngOnInit() {
   
    this.forgotForm = this.fb.group({
      
      password01: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      password02: new FormControl('', Validators.compose([
        Validators.required,
      ]))
      // more form inputs
    });
  }


  resetpass() { 
    var password = this.forgotForm.get("password01").value;
    var confirmpassword = this.forgotForm.get("password02").value;
    
    //console.log(confirmpassword);
    if(password == '' || confirmpassword == '' || password != confirmpassword)
    {
      //console.log("invalid");
      this.errmessage = "Passwords do not match";
      this.successmessage = "";
    }
    else{
      this.forgotForm.reset();
      this.errmessage = "";
      this.successmessage = "Password changed successfully";
    }
    
    }  

}
