import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-collection-detail',
  templateUrl: './collection-detail.component.html',
  styleUrls: ['./collection-detail.component.css']
})
export class CollectionDetailComponent implements OnInit {
  public isLoading: boolean = false;
  public detailTitle;
  public detailArray:any = [];
  constructor(private router : Router, private route: ActivatedRoute, public rest:RestService) { }

  ngOnInit() {
    this.isLoading = true;
    this.route.queryParams.subscribe(params => {
      this.detailTitle = params;

      console.log(this.detailTitle, 'detail');
  });
    this.rest.viewCollectionDetail(this.detailTitle).subscribe((result) => {
      if(result.status == "success"){  
        this.detailArray = result;
        this.isLoading = false;
      } else{
       //this.message = result.message;
      }
    }, (err) => {
      //this.message = err.error;
    });
  }

  // Detail view starts here
  detailView(id){
    // Call detail API
    this.rest.viewProduct(id).subscribe((result) => {
      if(result.status == "success"){  
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "assetid": id,
          "file_title": result.data["0"].file_title,
          "file_org_url": result.data["0"].file_org_url,
          "file_type": result.data["0"].file_type,
          "file_size": result.data["0"].file_size,
          "file_width": result.data["0"].file_width,
          "file_height": result.data["0"].file_height,
          "file_description": result.data["0"].file_description,
          "google_url": result.data["0"].google_url,
          "archived": result.data["0"].archived
        }
      }
          this.router.navigate(['/view/'], navigationExtras);
        } else{
        //this.message = result.message;
        }
      }, (err) => {
        //this.message = err.error;
      });
    }

}
