import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ShortlistModalComponent } from '../shortlist-modal/shortlist-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-all',
  templateUrl: './view-all.component.html',
  styleUrls: ['./view-all.component.css']
})
export class ViewAllComponent implements OnInit {
  public isLoading: boolean = false;
  public allData;
  showingData;
  public modalData = {
    image: '',
    id: '',
    action: ''
  }
  constructor(private router : Router, private route: ActivatedRoute, public rest:RestService, private modalService: NgbModal) { }

  ngOnInit() {
    this.isLoading = true;

    this.route.queryParams.subscribe(params => {
      this.allData = params;
      if(this.allData.recent){
        this.getRecentData();
      } else if(this.allData.download){
        this.getDownloads();
      } else if(this.allData.shortlist){
        this.getShortlists();
      }
  });
  }
  // Show recently viewed assets
  getRecentData(){
    this.rest.viewRecent().subscribe((result) => {
        if(result.status == "success"){
          this.showingData = result;
          this.isLoading = false;
        } else{
        }
      }, (err) => {
      });
    }

  // Show recently downloaded assets
  getDownloads(){
    this.rest.getAllDownloads().subscribe((result) => {
        if(result.status == "success"){
          this.showingData = result;
          this.isLoading = false;
        } else{
        }
      }, (err) => {
      });
    }

    // get all shortlisted assets
    getShortlists(){
      this.rest.getAllSortlist().subscribe((result) => {
        if(result.status == "success"){
          this.showingData = result;
          this.isLoading = false;
        } else{
        }
      }, (err) => {
      });
    }

    // Detail view starts here
detailView(id){
  
  // Call detail API
  this.rest.viewProduct(id).subscribe((result) => {
    if(result.status == "success"){  
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "assetid": id,
        "file_title": result.data["0"].file_title,
        "file_org_url": result.data["0"].file_org_url,
        "file_type": result.data["0"].file_type,
        "file_size": result.data["0"].file_size,
        "file_width": result.data["0"].file_width,
        "file_height": result.data["0"].file_height,
        "file_description": result.data["0"].file_description,
        "google_url": result.data["0"].google_url,
        "archived": result.data["0"].archived
      }
    }
        this.router.navigate(['/view/'], navigationExtras);
        this.isLoading = false;
      } else{
      //this.message = result.message;
      }
    }, (err) => {
      //this.message = err.error;
    });
  }

  openModal(img, id, action) {
    const modalRef = this.modalService.open(ShortlistModalComponent);
    modalRef.componentInstance.modalData = this.modalData;
    this.modalData.image = img;
    this.modalData.id = id;
    this.modalData.action = action;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.modalService.dismissAll();
      alert(receivedEntry);
        if(this.modalData.action == "remove")
        {
          this.getShortlists();
        }
      })
    }
}
