import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {
  public collectionData: any;
  public isLoading: boolean = false;
  constructor(public rest:RestService, private route: ActivatedRoute, private router : Router) { }

  ngOnInit() {
    this.isLoading = true;
    this.getCollections();
  }
  getCollections(){
    this.rest.getAllCollections().subscribe((result) => {
      if(result.status == "success"){
        this.collectionData = result;
        this.isLoading = false;
      } else{
      }
    }, (err) => {
      // this.message = err.error;
    });
  }
// Detail view starts here
showCollections(title){
// Call detail API
  let navigationExtras: NavigationExtras = {
    queryParams: {
      "title": title
  }
}
this.router.navigate(['/collection-detail/'], navigationExtras);
}
}
