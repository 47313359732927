import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
// import { ILogin } from 'src/app/interfaces/login';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { environment as env } from '../../environments/environment';
import { RestService } from 'src/app/services/rest.service';
import { AuthGuard } from '../guards/auth.guard';
import { NgbModule } from  '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  message: string;
  resetMsg: string;
  sliderimg: any = [];
  constructor(
    public rest:RestService,
    private fb : FormBuilder,
    private router : Router,
    //private authService : AuthService,
    private modalService: NgbModal,
    private authGuard : AuthGuard,
    private ngbmodule: NgbModule
 ) { }
  public env = env;
  loginForm: FormGroup;
  resetPwdForm: FormGroup;
  returnUrl: string;
  notAuth: string;



  ngOnInit() {
    this.returnUrl = '/home';
    this.notAuth = '/login';
    if(this.authGuard.isLoggedIn() == true){
      this.router.navigate([this.returnUrl]);
    } else{
      this.router.navigate([this.notAuth]);
    }

    //this.authService.logout();
    this.loginForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
      ]))
      // more form inputs
    })
    this.resetPwdForm = this.fb.group({
      pwEmail: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$')
      ])),
      // more form inputs
    })
    this.getSlider();
  }
  get f() { return this.loginForm; }
  get f2() { return this.resetPwdForm; }
  login() {

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
   } else {
           this.rest.login(this.loginForm).subscribe((result) => {
           if(result.status == "success"){
            sessionStorage.setItem('isLoggedIn', "true");
            sessionStorage.setItem('token', result.token);
            sessionStorage.setItem('email', this.loginForm["value"].email);
            this.authGuard.isLoggedIn() === true;
            window.location.reload();
            this.router.navigate([this.returnUrl]);


           } else{
            this.message = result.message;
           }
         }, (err) => {
           this.loginForm.reset();
           this.message = err.error;
         });
   }
    }


    getSlider(){
      this.rest.getSlider().subscribe((result) => {
          this.sliderimg = result;
      });
    }

    // Resend pwd submit
    sendEmail(){
      // stop here if form is invalid
      if (this.resetPwdForm.invalid) {
        return;
      } else {
              this.rest.sendEmail(this.resetPwdForm).subscribe((result) => {
              if(result.status == "success"){
                this.resetMsg='We have sent a password reset link to your email.';
              } else{
                this.resetMsg = result.message;
              }
            }, (err) => {
              this.resetPwdForm.reset();
              this.resetMsg = err.error;
            });
      }
    }

    closeResult: string;
    open(content) {
      this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'custom-class'
      }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
      }
    }
  }
