import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Post } from '../post.model';
import { PostFile } from '../postfile.model';
import { PostFile2 } from '../postfile2.model';
import { PostFile3 } from '../postfile3.model';
import { AddShortlist } from '../addshortlist.model';
import { environment } from 'src/environments/environment';



// Declare API url
const endpoint = environment.siteUrl;
const editorUrl = environment.editorUrl;
var token = sessionStorage.getItem('token');
var bearerToken = 'Bearer '+token;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json; charset=utf-8'
  })
};

const uploadOptions = {
  headers: new HttpHeaders({
  })
};

const tokenhttpOptions = {
  headers: new HttpHeaders({
    'Authorization': bearerToken,
    //'Accept': 'application/json',

      //mimeType: "multipart/form-data"
  })
};

const headers = new HttpHeaders({
    'Authorization': bearerToken,
  //'Accept': 'application/json',

   // mimeType: "multipart/form-data"
  });

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient) { }
  // To extract response for login API
  private extractData(res: Response) {
    let body = res;
    return body || { };
  }
  // Login click function
  login(model): Observable<any> {
    return this.http.post<any>(endpoint + 'login?email='+model.value.email+'&password='+model.value.password, JSON.stringify(model.value), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('login'))
    );
  }

  // Login slider function
  getSlider(): Observable<any> {
    const postData: Post = { keywords: ""};
    /* return this.http.post<any>('https://dam.royalenfield.com/getslider', postData, {headers:headers}).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getSlider'))
    ); */

    return this.http.get<any>('https://dam.royalenfield.com/getslider')
    // return this.http.get<any>('https://rebackend.interactiveavenues.net/getslider')
    .pipe(
      catchError(this.handleError<any>('getSlider', []))
    );
  }

  // Get user details
  userDetails(): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(endpoint + 'details', postData, {headers:headers}).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('userDetails'))
    );
  }

  // Reset password click
  sendEmail(model): Observable<any> {
    return this.http.post<any>(endpoint + 'password/create?email='+model.value.pwEmail, JSON.stringify(model.value), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('sendEmail'))
    );
  }

  // For Search API
  search(model): Observable<any> {
    console.log("here we are check search request data",model);
    
    return this.http.post<any>(endpoint + 'solr/search', JSON.stringify(model), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('search'))
    );
  }

  // For Archived images
  // searchArchive(model): Observable<any> {
  //   return this.http.post<any>(endpoint + 'solr/search', JSON.stringify(model), httpOptions).pipe(
  //     map(this.extractData),
  //     catchError(this.handleError<any>('searchArchive'))
  //   );
  // }

  // Pagination API
  searchByPage(number): Observable<any> {
    var numberJson = {
        "page": number
      }
    return this.http.post<any>(endpoint + 'solr/search', numberJson, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('searchByPage'))
    );
  }

  // For getting product list
  getProductList(): Observable<any> {
    //const postData: Post = { keywords: "mountain"};
    return this.http.get<any>(
      endpoint + 'getproductlist', {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getProductList'))
    );
  }

  // View detail page
  viewProduct(model): Observable<any> {
    return this.http.post<any>(endpoint + 'solr/getassetdetails?assetid='+model, JSON.stringify(model.value), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('viewProduct'))
    );
  }



  // View Request page
  showProduct(): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'getmyrequest', postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('showProduct'))
    );
  }


  // View Edited Images
  /* getEditImages(model): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      editorUrl + 'editor/count-my-design.php?uid='+model, postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getEditImages'))
    );

  } */

  // Download API here
  // downloadAsset(model): Observable<any> {
  //   return this.http.post<any>(endpoint + 'addtomydownload?assetid='+model, JSON.stringify(model.value), httpOptions).pipe(
  //     map(this.extractData),
  //     catchError(this.handleError<any>('downloadAsset'))
  //   );
  // }

  downloadAsset(model): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'addtomydownload?assetid='+model,postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('downloadAsset'))
    );
  }

  /* viewdownloadAsset(model): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'solr/getfile?path='+model+'&download=1',postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('viewdownloadAsset'))
    );
  }

  viewdownloadAsset(model): Observable<any> {

    return this.http.get<any>(endpoint + 'solr/getfile?path='+model)
    .pipe(
      catchError(this.handleError<any>('viewdownloadAsset', []))
    );
  }*/


  getCompleteURL(model): Observable<File> {
    let result: Observable<any> = this.http
      .get(endpoint + 'solr/getfile?path='+model, { responseType: "text" });
    return result;
  }

  reqasset(model, model1): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'addtorequest?assetid='+model+'&message='+model1,postData, {headers:headers}

    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('reqasset'))
    );
  }

  addToShortList(model): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'addtoshortlist?assetid='+model,postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('addToShortList'))
    );
  }

  removeFromShortList(model): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'removefromshortlist?assetid='+model,postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('removeFromShortList'))
    );
  }

  // To get all collections
  getAllCollections(): Observable<any> {
    return this.http.post<any>(endpoint + 'solr/getallcollections', httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getAllCollections'))
    );
  }
  viewCollectionDetail(title): Observable<any> {
    return this.http.post<any>(endpoint + 'solr/getallcollections?title='+title, JSON.stringify(title), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('viewCollectionDetail'))
    );
  }

  // Add to recent
  addToRecent(model): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'addtorecentview?assetid='+model, postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('addToRecent'))
    );
  }

  // Get recently viewed assets
  viewRecent(): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'getmyrecentview', postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('viewRecent'))
    );
  }

  // Get all downloaded assets
  getAllDownloads(): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'getmydownload', postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getAllDownloads'))
    );
  }

  // get all shortlist data
  getAllSortlist(): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'getmyshortlist', postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getAllSortlist'))
    );
  }

  // Logout
  logout(): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'logout', postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('logout'))
    );
  }

   // get unique label
  getUniqueLabel(): Observable<any> {
    const postData: Post = { keywords: ""};
    return this.http.post<any>(
      endpoint + 'getuniquelabel', postData, {headers:headers}
    ).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getUniqueLabel'))
    );
  }



  // Individual upload
  uploadIndividual(model, fileValue, radioValue): Observable<any> {
      console.log('model new', radioValue);
    var form = new FormData();
    form.append("title[]", model.title);
      form.append("uploadfile[]", fileValue);
      form.append("tags[]", model.tags); //this returns media object which contain all details of recorded video
      form.append("isillu", model.isillu);
      console.log('form', form);
    const postData: PostFile = { title: model.title, tags: model.tags, uploadfile:model.importFile, isillu: radioValue };
    console.log(postData);
    //const body = new HttpParams()
    //        .set('title[]', model.title)
    //        .set('uploadfile[]', model.importFile);

    return this.http.post<any>(
      endpoint + 'uploadajax', form, {
        reportProgress: true,
        observe: 'events',
        headers: headers
      },
        // endpoint + 'uploadajax', form, {headers: headers},
    ).pipe(
      // map(this.extractData),
      catchError(this.handleError<any>('uploadIndividual'))
    );
  }

  // Multiple upload
  submitMultiple(model, fileValue, radioValue): Observable<any> {
    console.log('model', model.importFile2);
  var form = new FormData();
  form.append("title", model.multiTitle);
    form.append("file", fileValue);
    form.append("tags", model.tags2); //this returns media object which contain all details of recorded video
    form.append("isillu", model.isilluColl);
    console.log('form', form);
  const postData2: PostFile2 = { title: model.multiTitle, tags: model.tags2, file :model.importFile2, isillu: radioValue  };
  console.log(postData2);
  //const body = new HttpParams()
  //        .set('title[]', model.title)
  //        .set('uploadfile[]', model.importFile);

  return this.http.post<any>(
      // endpoint + 'uploadzipfile', form, {headers: headers},
      endpoint + 'uploadzipfile', form, {
        reportProgress: true,
        observe: 'events',
        headers: headers
      }).pipe(
    //map(this.extractData),
    catchError(this.handleError<any>('submitMultiple'))
  );
}
// get unique label
getStats(): Observable<any> {
  const postData: Post = { keywords: ""};
  return this.http.post<any>(
    endpoint + 'getmystats', postData, {headers:headers}
  ).pipe(
    map(this.extractData),
    catchError(this.handleError<any>('getStats'))
  );
}



// google upload asset
googleuploadIndividual(model, radioValue, radios): Observable<any> {

  var form = new FormData();
  form.append("title", model.title);
    form.append("tags", model.tags);
    form.append("fileId", model.googleimportfile01.fileId);
    form.append("oAuthToken", model.googleimportfile01.oAuthToken);
    form.append("name", model.googleimportfile01.name);
    form.append("isillu", radioValue);
    form.append("istype", radios);
    form.append("_token", token);
    //console.log(form);

      const postData3: PostFile3 = {_token: token, title: model.title, tags: model.tags, fileId :model.googleimportfile01.fileId, oAuthToken:model.googleimportfile01.oAuthToken, name:model.googleimportfile01.name, isillu: radioValue, istype: radios };

      //console.log(postData3);

      return this.http.post<any>(
        endpoint + 'downloadgoogle', form, {headers:headers, responseType: 'text' as 'json'}
      ).pipe(
        map(this.extractData),
        catchError(this.handleError<any>('googleuploadIndividual'))
      );


}

  // To handle error
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }



}
