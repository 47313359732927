import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { ViewAllComponent } from './view-all/view-all.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CollectionsComponent } from './collections/collections.component';
import { CollectionDetailComponent } from './collection-detail/collection-detail.component';
import { AllImagesComponent } from './all-images/all-images.component';
import { AllVideosComponent } from './all-videos/all-videos.component';
import { AssetUploadComponent } from './asset-upload/asset-upload.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ViewRequestComponent } from './view-request/view-request.component';
import { GoogleAssetUploadComponent } from './google-asset-upload/google-asset-upload.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate : [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate : [AuthGuard] },
  { path: 'all-images', component: AllImagesComponent, canActivate: [AuthGuard] },
  { path: 'all-videos', component: AllVideosComponent, canActivate: [AuthGuard] },
  { path: 'all-collections', component: CollectionsComponent, canActivate: [AuthGuard] },
  { path: 'collection-detail', component: CollectionDetailComponent, canActivate: [AuthGuard] },
  { path: 'view-all', component: ViewAllComponent, canActivate: [AuthGuard] },
  { path: 'view', component: ProductDetailComponent, canActivate: [AuthGuard] },
  { path: 'asset-upload', component: AssetUploadComponent, canActivate: [AuthGuard] },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'view-request', component: ViewRequestComponent, canActivate: [AuthGuard] },
  { path: 'google-asset-upload', component: GoogleAssetUploadComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  ngOnInit() {

  }
 }
