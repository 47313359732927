import { Component } from '@angular/core';
import { Router } from '@angular/router';  
import { AuthGuard } from './guards/auth.guard'; 
import { AuthService } from './services/auth.service'
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'UGC Tool';
  authorised: boolean = false;
  constructor(private router: Router, private authGuard : AuthGuard, private authService: AuthService, private bnIdle: BnNgIdleService) { // initiate it in your component constructor)
  }
  ngOnInit() {
    if(this.authGuard.isLoggedIn() == true){
      this.authorised = true;
    } else{
      this.authorised = false;
    }
    this.bnIdle.startWatching(1800).subscribe((res) => {
      if(res) {
          this.authService.logout(); 
          window.location.reload();
          this.router.navigate(['/login']);  
      }
    })
    }
}
