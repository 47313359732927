import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shortlist-modal',
  templateUrl: './shortlist-modal.component.html',
  styleUrls: ['./shortlist-modal.component.css']
})
export class ShortlistModalComponent implements OnInit {
  public addMe:boolean = false;
  public removeMe:boolean = false;
  public showingshortlistData: any;

  @Input() public modalData;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(public rest:RestService, private modalService: NgbModal) { }

  ngOnInit() {
    if(this.modalData.action === 'remove'){
      this.removeMe = true;
     
    } else {
      this.addMe = true;
    }
    
  }
  passBack() {
    console.log(this.modalData);
    if(this.modalData.action !== 'remove'){
      this.rest.addToShortList(this.modalData.id).subscribe((result) => {
        if(result.status == "success"){
          this.passEntry.emit(result.message);
        } else{
          this.passEntry.emit(result.message);
        }
      }, (err) => {
        // this.message = err.error;
      });
    } else {
      this.rest.removeFromShortList(this.modalData.id).subscribe((result) => {
        if(result.status == "success"){
          this.passEntry.emit(result.message);
        } else{
          this.passEntry.emit(result.message);
        }
      }, (err) => {
        // this.message = err.error;
      });
    }
    }

    closeModal(){ 
      this.modalService.dismissAll();
      
    }
    
    
}
