import { Injectable } from '@angular/core';
//import { ILogin } from 'src/app/interfaces/login'; 

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }    
  getAuthToken(): string {
    return sessionStorage.getItem('token');
  }
   logout(){    
    sessionStorage.setItem('isLoggedIn','false');    
    sessionStorage.removeItem('token');    
    sessionStorage.removeItem('email');  
   }  
   isLoggedIn(){
    sessionStorage.getItem('isLoggedIn');    
    sessionStorage.getItem('token');   
    //console.log(sessionStorage, 'sessionstorage');
    return true;
   }
}
