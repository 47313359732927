import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { HttpClient} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';

// Declare API url
const editorUrl = environment.editorUrl;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent implements OnInit {
  public isLoading: boolean = false;
  id: string;
  recentData: any;
  recentNoData: boolean = false;
  downloadData: any;
  downloadNoData: boolean = false;
  requestdata: any;
  requestNoData: boolean = false;
  shortlistData: any;
  shortlistNoData: boolean = false;
  userid: any;
  posts: any;
  public tokenvalue :string;

  uploadedValue: number;
  publishedValue: number;
  rejectedValue: number;

  constructor(private router: Router, private authService: AuthService, public rest:RestService, private http: HttpClient) { }

  ngOnInit() {
    this.isLoading = true;
    this.id = sessionStorage.getItem('token');
    this.recentlyViewed();
    this.getDownloads();
    this.getShortlists();
    this.requestedasset();
    this.getStatistics();
    this.getUserId();
    //this.getactiveTab();
    //this.getEditCount();

    this.tokenvalue = sessionStorage.getItem('token');
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  // Show recently viewed assets
  recentlyViewed(){
    this.rest.viewRecent().subscribe((result) => {
      console.log('recentNoData', this.recentNoData);
        if(result.status == "success"){
          this.recentData = result;
          this.isLoading = false;
          if(result.data == undefined){
            this.recentNoData = true;
          }
        } else{
        }
      }, (err) => {
      });
    }

  // Show recently downloaded assets
  getDownloads(){
    this.rest.getAllDownloads().subscribe((result) => {
        if(result.status == "success"){
          this.downloadData = result;
          this.isLoading = false;
          if(result.data == undefined){
            this.downloadNoData = true;
          }
        } else{
        }
      }, (err) => {
      });
    }


  // Show requested assets
  requestedasset(){
    this.rest.showProduct().subscribe((result) => {
        if(result.status == "success"){
          this.requestdata = result;
          this.isLoading = false;
          if(result.data == undefined){
            this.requestNoData = true;
          }
        } else{
        }
      }, (err) => {
      });
    }


    // show edited images
    getUserId(){
      this.rest.userDetails().subscribe((result) => {
        if(result.status == "success"){
          this.userid = result.data.id;

          /*  this.http.get(environment.editorUrl+'/editor/count-my-design.php?uid=' + this.userid).subscribe((post) => {
            this.posts = [post];
            console.log(this.posts);
          });  */

        } else{
        }
      }, (err) => {
      });
    }


    /* getEditCount(){
      this.rest.getEditImages(this.userid).subscribe((result) => {
        if(result.status == "success"){
          console.log(result, 'new result');
        } else{
        }
      }, (err) => {
      });
    } */


    getactiveTab(){
      window.open(editorUrl + '?token=' + this.tokenvalue + '&active=mydesignTab', "_blank");
    }

    // get all shortlisted assets
    getShortlists(){
      this.rest.getAllSortlist().subscribe((result) => {
        if(result.status == "success"){
          this.shortlistData = result;
          this.isLoading = false;
          if(result.data == undefined){
            this.shortlistNoData = true;
          }
        } else{
        }
      }, (err) => {
      });
    }

    detailView(id){
      // Call detail API
      this.rest.viewProduct(id).subscribe((result) => {
        if(result.status == "success"){
        let navigationExtras: NavigationExtras = {
          queryParams: {
            "assetid": id,
            "file_title": result.data["0"].file_title,
            "file_org_url": result.data["0"].file_org_url,
            "file_type": result.data["0"].file_type,
            "file_size": result.data["0"].file_size,
            "file_width": result.data["0"].file_width,
            "file_height": result.data["0"].file_height,
            "file_description": result.data["0"].file_description,
            "google_url": result.data["0"].google_url,
            "archived": result.data["0"].archived
          }
        }
            this.router.navigate(['/view/'], navigationExtras);
          } else{
          //this.message = result.message;
          }
        }, (err) => {
          //this.message = err.error;
        });
      }

    // get all shortlisted assets
    getStatistics(){
      this.rest.getStats().subscribe((result) => {
        if(result.status == "success"){
          let statData = result.data[0];

          this.uploadedValue = statData.uploaded_asset;
          this.publishedValue = statData.published_asset;
          this.rejectedValue = statData.rejected_asset;

          this.isLoading = false;
          if(result.data == undefined){
            this.shortlistNoData = true;
          }
        } else{
        }
      }, (err) => {
      });
    }
}
