import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ShortlistModalComponent } from '../shortlist-modal/shortlist-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { concat } from 'rxjs';
import {​​​​​​​​ fromEvent }​​​​​​​​ from'rxjs';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// Declare API url
const editUrl = environment.editorUrl;

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})


export class ProductDetailComponent implements OnInit {
  public isLoading: boolean = true;
  public detailData:any = [];
  public reqData:any = [];
  public similarTags: any;
  public similarData: any;

  public viewsimilarTags: any;

  purposemsg: string;
  sendreqdownloadForm: FormGroup;
  public showmesg: boolean = false;
  public assetid :string;
  public purposeInput :string;
  showMessage: boolean = true;
  hideMessage: boolean = true;
  showspecialimg: boolean = true;
  acessreq: boolean = true;
  public requested: boolean = false;
  tab : any = 'tab1';
  returnUrl: string;
  public searchParams: any = {};
  public tokenvalue :string;
  public imgUrl: any;
  codec = new HttpUrlEncodingCodec;
  innerWidth: number;
  viewMode = 'tab1';
  numbercells: number;
  showarrow: boolean = false;
  public finalwidth :number;
  public finalheight :number;
  showpopup: boolean = false;


  public suitableCopy:string ="";
  public imgHeight:number;
  public imgWidth:number;
  public file_dpi: number;


  public detailResult;
  public modalData = {
    image: '',
    id: ''
  }
  constructor(private router : Router, private route: ActivatedRoute, public rest:RestService, private modalService: NgbModal, private fb : FormBuilder) { }


  ngOnInit() {
    this.returnUrl = '/all-images';
    this.isLoading = true;
    if(this.route.queryParams["value"] !== ''){
      this.route.queryParams.subscribe(params => {
        // this.assetid = params["assetid"];
        // this.detailResult = params["result"];
        this.detailResult = params;
        console.log(params, 'params new');
        this.searchParams['archived'] = params.archived;
        this.searchParams['assetid'] = params.assetid;
        this.searchParams['file_description'] = params.file_description;
        this.searchParams['file_height'] = params.file_height;
        this.searchParams['file_org_url'] = params.file_org_url;
        this.searchParams['file_size'] = params.file_size;
        this.searchParams['file_title'] = params.file_title;
        this.searchParams['file_type'] = params.file_type;
        this.searchParams['file_width'] = params.file_width;
        this.searchParams['google_url'] = params.google_url;
        this.searchParams['page'] = params.page;

        console.log(params, 'search new');
    });
    this.addToRecentView();
    this.viewProduct();
    this.viewRequests(this.detailResult);
    //this.viewsearch();

    } else{
    }

    this.sendreqdownloadForm = this.fb.group({
      addpurpose: new FormControl('', Validators.compose([
        Validators.required
      ])),
    })

    if(this.imgHeight >= 0 && this.imgWidth >= 0 && this.file_dpi <= 200){
      this.suitableCopy = "Suitable for digital/social media use only";
    } else if(this.imgHeight >= 1000 && this.imgWidth >= 1000 && this.file_dpi > 200){
      this.suitableCopy = "Suitable for small prints and digital use";
    } else {
      this.suitableCopy = "Suitable for large prints as well as digital use";
    }

    fromEvent(window, 'popstate')
    .subscribe((e) => {​​​​​​​​
      console.log(e, 'back button');
    }​​​​​​​​);

    this.tokenvalue = sessionStorage.getItem('token');
    this.getdownloadimage();

    this.innerWidth = window.innerWidth;
    //console.log(this.innerWidth);

    if(this.innerWidth > 991){
      this.numbercells = 4;
      if(this.numbercells > 4){
        this.showarrow = true;
      }
    }

  }



  get f() { return this.sendreqdownloadForm; }
  viewRequests(values){
    this.isLoading = true;
    var mainId = parseInt(values.assetid, 10);
    this.rest.showProduct().subscribe((result) => {
      if(result.status == "success"){
        let reqArray = result.data;
        reqArray.forEach(function (value) {
          console.log(value.assetid, 'for each');
          console.log(mainId, 'main value');
          if(value.assetid == mainId){
            this.requested = true;
            console.log('true', value);
          } else{
            console.log('false', value);
          }
          this.isLoading = false;
        });
        //console.log(this.viewData);
        console.log(this.requested, 'requested');
      } else{
       //this.message = result.message;
      }

    }, (err) => {
      //this.message = err.error;
    });
  }
  addToRecentView(){
  // Add to recent viewed if success
  this.isLoading = true;
  this.rest.addToRecent(this.detailResult.assetid).subscribe((result) => {
    if(result.status == "success"){
      this.isLoading = false;
      // Success
    } else {
      // Unsuccesful
    }
  }, (error) => {
    // Error
  });
  }


   getdownloadimage(){
    this.rest.getCompleteURL(this.detailResult.google_url).subscribe((result) => {
      this.imgUrl = result;
      this.imgUrl = this.codec.encodeValue(this.imgUrl);
      return this.imgUrl;
     // console.log(this.imgUrl);
    }, (error) => {
      // Error
    });
    }


    CalculateDim(w,h,f){
      //this.finalwidth = (w/96);
      //this.finalheight = (h/96);
      this.showpopup = false;

      if(f == "pixel"){
        this.finalwidth = (w/96);
        this.finalheight = (h/96);
      }

      if(f == "feet"){
        var ftwd = (w*1152);
        var ftht = (h*1152);
        this.finalwidth = (ftwd/96);
        this.finalheight = (ftht/96);
      }

      if(f == "centi"){
        var ftwd = (w*37.79);
        var ftht = (h*37.79);
        this.finalwidth = (ftwd/96);
        this.finalheight = (ftht/96);
      }

      if(f == "inch"){
        this.finalwidth = w;
        this.finalheight = h;
      }

     window.open(editUrl + '?token=' + this.tokenvalue + '&w=' + this.finalwidth + '&h=' + this.finalheight + '&imgsrc=' + this.imgUrl, "_blank");


    }

    closepopUp(){

      this.showpopup = false;
    }


    OpenPopup(){
      this.showpopup = true;
    }




  viewProduct(){
    this.isLoading = true;
    this.rest.viewProduct(this.detailResult.assetid).subscribe((result) => {
      if(result.status == "success"){
        console.log(result, 'sucess result');
        this.isLoading = false;
        this.detailData = result;
        this.file_dpi = result.data['0'].file_dpi;
        this.imgHeight = result.data['0'].file_height;
        this.imgWidth = result.data['0'].file_width;
        this.isLoading = false;
        this.similarTags = result.data['0'].file_tags.split(',', 4);
        this.viewsimilarTags = result.data['0'].file_tags;
        this.searchParams["keywords"] = this.similarTags.toString();
        this.search();
      } else{
       //this.message = result.message;
      }
    }, (err) => {
      //this.message = err.error;
    });
  }
  search() {
    this.isLoading = true;
    this.rest.search(this.searchParams).subscribe((result) => {
      if(result.status == "success"){
        //console.log(result, 'result');
        this.similarData = result;
        this.isLoading = false;
      } else{
      }
    }, (err) => {
    });
    }


    viewsearch() {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "keywords": this.viewsimilarTags
        }
      }
      this.router.navigate([this.returnUrl], navigationExtras);
    }

  // Search by similar
  // similarSearch(similarName){
  //   this.isLoading = true;
  //   this.searchParams.keywords = JSON.stringify(similarName);
  //   this.search(similarName);
  // }
  // Detail view starts here
  detailView(id){
    // Call detail API
    this.isLoading = true;
    this.rest.viewProduct(id).subscribe((result) => {
      if(result.status == "success"){
        this.isLoading = false;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "assetid": id,
          "file_title": result.data["0"].file_title,
          "file_org_url": result.data["0"].file_org_url,
          "file_type": result.data["0"].file_type,
          "file_size": result.data["0"].file_size,
          "file_width": result.data["0"].file_width,
          "file_height": result.data["0"].file_height,
          "file_description": result.data["0"].file_description,
          "google_url": result.data["0"].google_url,
          "archived": result.data["0"].archived,
          "page": this.searchParams["page"]
        }
      }
          this.router.navigate(['/view/'], navigationExtras);
          window.scroll(0, 0);
        } else{
        //this.message = result.message;
        }
      }, (err) => {
        //this.message = err.error;
      });
    }
  openModal(img, id) {
    const modalRef = this.modalService.open(ShortlistModalComponent);
    modalRef.componentInstance.modalData = this.modalData;
    this.modalData.image = img;
    this.modalData.id = id;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.modalService.dismissAll();
      alert(receivedEntry);
      })
    }

    downloadFile(file, text) {
      //creating an invisible element
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8, '
                           + encodeURIComponent(text));
      element.setAttribute('download', file);

      //the above code is equivalent to
      // <a href="path of file" download="file name">

      document.body.appendChild(element);

      //onClick property
      element.click();

      document.body.removeChild(element);
  }

  // Start file download.
  // document.getElementById("btn").addEventListener("click", function() {
  //     // Generate download of hello.txt file with some content
  //     var text = document.getElementById("text").value;
  //     var filename = "GFG.txt";

  //     download(filename, text);
  // }, false);

    downloadApi(assetid) {
    window.location.href=environment.siteUrl+'solr/getfile?path=' +this.detailResult.google_url+ '&download=1';

      this.rest.downloadAsset(assetid).subscribe((result) => {
        if(result.status == "success"){
          //this.detailData = result;
          //this.downloadFile('filename', 'text');
        } else{
         //this.message = result.message;
        }
      }, (err) => {
        //this.message = err.error;
      });
    }

    requestpermission(){
      // console.log("hi");
       this.showMessage = !this.showMessage;
       this.hideMessage = false;
     }


    sendreq(assetid, purposeInput)
     {


      var newdiv = document.getElementById('maindiv');

      if(purposeInput!="")
      {
        this.showspecialimg = false;
        this.sendreqdownloadForm.reset();
        this.rest.reqasset(assetid, purposeInput).subscribe((result) => {
          if(result.status == "success"){
            this.reqData = result;
            //console.log(this.reqData);
            newdiv.innerHTML = result.message;
            this.acessreq = false;
          } else{
            newdiv.innerHTML = result.message;
            this.acessreq = false;
          }
        }, (err) => {
          //this.message = err.error;
        });
      }
      else
      {
        this.purposemsg = "Please fill in the purpose for download";
      }
     }

     onClick(check){
         //console.log(check);
          if(check==1){
            this.tab = 'tab1';
          }else if(check==2){
            this.tab = 'tab2';
          }else{
            this.tab = 'tab3';
          }

      }

      tagClick(keywordName:any) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            "keywords": keywordName
        }
        }
        this.router.navigate([this.returnUrl], navigationExtras);
        }

        InputBoxShow(){
          this.showmesg = !this.showmesg;
        }



}
