import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarModule } from 'ng-sidebar';
import { HomeComponent } from './home/home.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ShortlistModalComponent } from './shortlist-modal/shortlist-modal.component';
import { CollectionsComponent } from './collections/collections.component';
import { CollectionDetailComponent } from './collection-detail/collection-detail.component';
import { AllImagesComponent } from './all-images/all-images.component';
import { AllVideosComponent } from './all-videos/all-videos.component';
import { ViewAllComponent } from './view-all/view-all.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { JwPaginationComponent } from 'jw-angular-pagination';
import { AssetUploadComponent } from './asset-upload/asset-upload.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ViewRequestComponent } from './view-request/view-request.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { GoogleAssetUploadComponent } from './google-asset-upload/google-asset-upload.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ProductDetailComponent,
    ShortlistModalComponent,
    CollectionsComponent,
    CollectionDetailComponent,
    AllImagesComponent,
    AllVideosComponent,
    ViewAllComponent,
    AssetUploadComponent,
    ForgotPasswordComponent,
    ViewRequestComponent,
    GoogleAssetUploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    NgxSliderModule,
    IvyCarouselModule

  ],
  providers: [BnNgIdleService], // add it to the providers of your module
  // providers: [AuthGuard, {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: LoaderInterceptorService,
  //   multi: true
  // }],
  bootstrap: [AppComponent],
  entryComponents: [ ShortlistModalComponent ]
})
export class AppModule { }
